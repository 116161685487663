import dayjs from 'dayjs';

export const isAuthorizedRouter = (path) => {
  return path !== '/' &&
    !path.startsWith('/JamSession/') &&
    !path.startsWith('/JamSong/') &&
    !path.startsWith('/profile/')
}

export function formatFileSize(bytes) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function changeFileExtension(filename, newExtension) {
  // Use regex to replace the existing extension with the new one
  return filename.replace(/\.[^/.]+$/, `.${newExtension}`);
}

export function downloadFromUrl(url, filename) {
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// Format time in minutes:seconds
export function formatTime(time) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  const hundredths = Math.floor((time * 100) % 100);

  return `${hours > 0 ? hours + ':' : ''}${minutes < 10 && hours > 0 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}.${hundredths < 10 ? '0' : ''}${hundredths}`;
}

export function roundDecimal(time, digits = 2) {
  return Number(time.toFixed(digits))
}

export function formatDate(dateString, format = 'MM-DD-YYYY') {
  if (!dateString) return '';
  return dayjs(dateString).format(format);
}

export function calculateAge(birthdate) {
  const birthDate = dayjs(birthdate, 'MM-DD-YYYY');
  const today = dayjs();
  const age = today.diff(birthDate, 'year');
  return age;
}

export function generateUsername(email, existingUsernames) {
  const cleanEmail = email
      .split('@')[0]
      .toLowerCase()
      .replace(/[^a-z0-9._-]/g, '');

  let baseUsername = `${cleanEmail}`;

  let uniqueUsername = baseUsername;
  let counter = 1;

  while (existingUsernames.includes(uniqueUsername)) {
      uniqueUsername = `${baseUsername}${counter}`;
      counter++;
  }

  return uniqueUsername;
}
