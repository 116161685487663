import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import vuetify from '@/plugins/vuetify';

import router from './router';
import store from "./store";
import * as filter from './filter';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '@/config/firebase';

import App from './App.vue';
import { isAuthorizedRouter } from './utils/util';
import VueSocials from 'vue-socials';
import './index.css';

const head = createHead();
const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(store);
app.use(head);
app.use(VueSocials);

// Global Var 
app.config.globalProperties.$store = store;
app.config.globalProperties.$filters = filter;
app.config.globalProperties.$router = router;

app.mount('#app');

let isAuthInitialized = false;

// Handle authentication state changes and routing logic
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log('User is signed in:', user);
    store.state.user = user;
    // If the user is on the home page and logged in, redirect to groups
    if (router.currentRoute.value.path === '/') {
      router.replace('/sessions');
    }
  } else {
    console.log('User is signed out');
    // If the user is signed out, redirect to home page
    if (isAuthorizedRouter(router.currentRoute.value.path)) {
      router.replace('/');
    }
  }

  isAuthInitialized = true;
});

// Add router guard to handle navigation logic
router.beforeEach((to, from, next) => {
  if (!isAuthInitialized) {
    return next();
  }

  if (isAuthorizedRouter(to.path) && !auth.currentUser) {
    next({ path: '/' });
  } else if (to.path === '/' && auth.currentUser) {
    next({ path: '/' });
  } else {
    next();
  }
});
